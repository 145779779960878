<template>
  <div class="news">
    <normaltop
      :url="require('../assets/images/news/bg.png')"
      title="行业资讯"
      en="Industry information"
    ></normaltop>
    <div class="news-cont">
      <div
        class="part"
        v-for="item in news"
        :key="item.id"
        @click="toNewsDesc(item)"
      >
        <div class="date-box">
          <img v-if="item.cover!=null&&item.cover!=''&&item.type==2" :src="item.cover" alt="" :onerror="NewserrorImg"/>
          <img v-if="item.cover!=null&&item.cover!=''&&item.type==1" :src="item.cover" alt="" :onerror="NoticeerrorImg"/>
          <img v-if="(item.cover==''||item.cover==null)&&item.type==2" src="../assets/images/news/defaultNewsimg.png" alt=""/>
          <img v-if="(item.cover==''||item.cover==null)&&item.type==1" src="../assets/images/news/defaultNoticeimg.png" alt=""/>
          <!-- <h1>{{ item.created_at.substring(8, 10) }}</h1>
          <p>{{ item.created_at.substring(5, 7) }}月</p> -->
        </div>
          <div class="news-main">
            <h3 class="news-title">
              <span :class="item.type == 1 ? 'red-text' : 'yellow-text'"
                >【{{ item.type == 1 ? '公告' : '新闻' }}】</span
              >
              {{ item.name }}
            </h3>
            <!-- <p class="news-desc">{{ item.brief }}</p> -->
          </div>
          <p class="time">{{ item.push_date }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop.vue'
let _this
export default {
  name: 'News',
  components: { Normaltop },
  data() {
    return {
      news: [],
      NewserrorImg:'this.src="' + require('../assets/images/news/defaultNewsimg.png') + '"',
      NoticeerrorImg:'this.src="' + require('../assets/images/news/defaultNoticeimg.png') + '"'
    }
  },
  created() {
    _this = this
    this.init()
  },
  methods: {
    init() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'article')
        .then(data => {
          if (data.data.code == 200) {
            _this.news = data.data.data
            this.news.sort(function(a, b) {
              return a.push_date < b.push_date ? 1 : -1
            })
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    toNewsDesc(item) {
      let data = {
        id: item.id,
        type: item.type,
      }
      this.$router.push({ path: 'newsdesc', query: data })
      // this.$router.push({ name: 'NewsDesc', params: item })
    },
  },
}
</script>
<style lang="less" scoped>
.news {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  .news-cont {
    width: 60%;
    min-width: 1100px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    cursor: pointer;
    .part {
      position: relative;
      display: flex;
      align-items: center;
      padding: 45px 0;
      border-bottom: 1px solid #f6f6f6;
      .date-box {
        width: 120px;
        height: 100px;
        color: #797979;
        text-align: center;
        object-fit: fill;
        h1 {
          font-weight: normal;
          font-size: 48px;
        }
        img {
          width: 100%;
          height: 120%;
          position: relative;
          position: relative;
          bottom: 10%;
          right: 10%;
        }
      }
      .news-main{
        width: 80%;
      }
      .news-title {
        color: #000000;
        font-weight: bold;
        font-size: 25px;
        padding-left: 25px;
        padding-bottom: 40px;
        // width: %;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .red-text {
          color: #960f23;
        }
        .yellow-text {
          color: #c9850e;
        }
      }
      .news-desc {
        padding: 10px 20px;
        color: #333;
        line-height: 1.5;
      }
    }
  }
  .time {
    // padding: 15px 0 0 100px;
    font-size: 18px;
    color: #7f7f7f;
    text-align: right;
    position: absolute;
    bottom:45px;
    right:0px
  }
}
</style>
